import './NavBar.css'
import React from "react";
import { Component } from 'react';
import { Link } from 'react-router-dom';

import logo from '../../images/logos/JamBurLogoSmall.png'

class NavBar extends Component {
    render () {
        const { onRouteChange, loggedIn } = this.props;

        let navbarRightDisplay = <div className="navbar-right"></div>
        
        if (loggedIn){
            navbarRightDisplay = (
                <div className="navbar-right">
                    <Link to="/account" className="nav-link"><p>Account</p></Link>
                </div>
            );
        }else{
            navbarRightDisplay = (
                <div className="navbar-right">
                    <Link to="/login" className="nav-link"><p>Login</p></Link>
                    <Link to="/register" className="nav-link"><p>Register</p></Link>
                </div>
            );
        }
        return (
            <nav className='nav-container'>
                <div className='navbar-left'>
                    <Link to="/" className="nav-link logo"><img src={logo} alt="Logo" className="nav-link logo" /></Link>
                    <Link to="/" className='nav-link'><p>Home</p></Link>
                    <Link to="/twitch-tools" className='nav-link'><p>Twitch Tools</p></Link>
                    <Link to="/ai-tools" className='nav-link'><p>AI Tools</p></Link>
                </div>
                {navbarRightDisplay}
            </nav>
        );
    }
}

export default NavBar;