import './App.css';
import { Component } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useNavigate } from 'react-router-dom';

import NavBar from './components/nav-bar/NavBar';
import Footer from './components/footer/Footer';
import HomePage from './components/pages/home-page/HomePage';
import AiToolsPage from './components/pages/ai-tools-page/AiToolsPage';
import CoverLetterGeneratorPage from './components/pages/ai-tools-page/coverletter-generator-page/CoverLetterGeneratorPage';
import ChatbotPage from './components/pages/ai-tools-page/chatbot-page/ChatbotPage';
import TTSPage from './components/pages/ai-tools-page/tts-page/TTSPage';
import SigninPage from './components/pages/login-page/LoginPage';
import RegisterPage from './components/pages/register-page/RegisterPage'
import AccountPage from './components/pages/account-page/AccountPage';
import Loading from './components/loading/Loading';

import TwitchToolsPage from './components/pages/twitch-tools-page/TwitchToolsPage';

//refer to these for learning to implement jwt:
//https://www.youtube.com/watch?v=W-sZo6Gtx_E&ab_channel=PedroTech - Register and Login Page Tutorial | ReactJs, NodeJS, MySQL (Beginner)
//https://www.youtube.com/watch?v=KgXT63wPMPc&ab_channel=PedroTech - Authentication with JWT Tutorial - React, NodeJS | How to
//https://www.youtube.com/watch?v=sTHWNPVNvm8&ab_channel=PedroTech - Register and Login Tutorial | ReactJS, NodeJS, MySQL - Cookies, Sessions, Hashing
class App extends Component {
  constructor(){
    super();
    this.state = {
      loading: false,
      loginStatus: {
        loggedIn: false,
        user: {
          id: "",
          username: "",
          email: ""
        }
      }
    }
  }

  componentDidMount(){
    if (!this.state.loginStatus.loggedIn){
      this.validateSession();
    }
  }

  validateSession = () => {
    (async () => {
        let profileUrl = `${process.env.REACT_APP_BACKEND_URL}/profile`
        this.setState({loading: true});
        try {
          const response = await fetch(profileUrl, {
              method: "get",
              credentials: 'include'
          });
          const data = await response.json();
          if(data.id){
            console.log(`Validate Success!`);
            this.loadUser(true, data.id, data.username, data.email);
          }else{
              console.log("Validate Failed!");
              this.loadUser(false, "", "", "");
          }
        } catch (err) {
            console.error(err);
        }
        this.setState({loading: false});
    })();
  }

  loadUser = (loggedIn, id, username, email) => {
    this.setState({loginStatus: {
      loggedIn: loggedIn,
      user: {
        id: id,
        username: username,
        email: email
      }
    }});
  }
  
  PageLayout = ({children}) => (
    <>
      <header className="App-header">
        <NavBar onRouteChange={this.onRouteChange} loggedIn={this.state.loginStatus.loggedIn} />
      </header>
      <body className='content'>
          {children}
      </body>
      <footer>
        <Footer/>
      </footer>
    </>
  );

  ProtectedRoute = ({children}) => {
    const loggedIn = this.state.loginStatus.loggedIn
    if(!loggedIn){
      return <Navigate to="/login" replace />
    }else{
      return children;
    }
  }

  render(){
    const PageLayout = this.PageLayout;
    const ProtectedRoute = this.ProtectedRoute;
    console.log(this.state.loginStatus);

    if (this.state.loading) {
      return <Loading/>
    }

    return (
      <div className="app">
        <Router>
          <Routes>
            <Route path='/' element={<PageLayout><HomePage /></PageLayout>} />
            <Route path='/twitch-tools' element={<PageLayout><TwitchToolsPage /></PageLayout>} />
            <Route path='/ai-tools' element={<PageLayout><AiToolsPage /></PageLayout>} />
            <Route path='/ai-tools/chatbot' element={<PageLayout><ProtectedRoute><ChatbotPage /></ProtectedRoute></PageLayout>} />
            <Route path='/ai-tools/tts' element={<PageLayout><ProtectedRoute><TTSPage /></ProtectedRoute></PageLayout>} />
            <Route path='/ai-tools/coverletter' element={<PageLayout><ProtectedRoute><CoverLetterGeneratorPage /></ProtectedRoute></PageLayout>} />
            <Route path='/login' element={<PageLayout><SigninPage loadUser={this.loadUser} /></PageLayout>} />
            <Route path='/register' element={<PageLayout><RegisterPage loadUser={this.loadUser} /></PageLayout>} />
            <Route path='/account' element={<PageLayout><ProtectedRoute><AccountPage loadUser={this.loadUser} user={this.state.loginStatus.user} /></ProtectedRoute></PageLayout>} />
          </Routes>
        </Router>
      </div>
    );
    
  }
}

export default App;
