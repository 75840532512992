import './PageBanner.css'
import React from "react";
import { Component } from 'react';
import { Link, useNavigate } from 'react-router-dom';

const PageBanner = (props) => {
    const { title, image, description, page } = props;
    const navigate = useNavigate();

    return (
        <div className="page-banner-panel">
            <img src={image} />
            <div className='page-banner-info'>
                <h1>{title}</h1>
                <p>{description}</p>
                <Link to={page}><button className='button3'>Use</button></Link>
            </div>
        </div>
    );
}

export default PageBanner;