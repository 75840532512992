import './CoverLetterGeneratorPage.css'
import React, { useState } from "react";

const CoverLetterGeneratorPage = (props) => {
    const [inputAllowed, setInputAllowed] = useState(true);
    const [companyName, setCompanyName] = useState("");
    const [name, setName] = useState("");
    const [jobDescription, setJobDescription] = useState("");
    const [resume, setResume] = useState("");
    const [instructions, setInstructions] = useState("");
    const [generatedCoverLetter, setGeneratedCoverLetter] = useState("");

    const getCoverLetterResponse = async() => {
        try {
            setInputAllowed(false);
            let coverLetterUrl = `${process.env.REACT_APP_BACKEND_URL}/v1/coverlettergen`
            const response = await fetch(coverLetterUrl, {
                method: "post",
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({ company_name: companyName, applicant_name: name, job_description: jobDescription, resume: resume, instructions: instructions }),
                credentials: 'include'
            });
            const data = await response.json();
            setInputAllowed(true);
            return data['response'];
        } catch (err) {
            setInputAllowed(true);
            console.error(err);
        }
    }

    const onGenerate = async(event) => {
        setGeneratedCoverLetter("");
        let response = await getCoverLetterResponse();
        setGeneratedCoverLetter(response);
        console.log("response", response);
    }

    return (<>
    <h1>Cover Letter Generator</h1>
    <hr/>
    <div className='model-inputs'>
        <label name="companyName">Company Name</label>
        <input name="companyName" placeholder="Enter Company Name..." disabled={!inputAllowed} onChange={(event) => setCompanyName(event.target.value)}/>

        <label name="userName">Your Name</label>
        <input name="userName" placeholder="Enter Name..." disabled={!inputAllowed} onChange={(event) => setName(event.target.value)}/>

        <label name="jobDescription">Job Description</label>
        <textarea name="jobDescription" placeholder="Enter description of job position..." disabled={!inputAllowed} onChange={(event) => setJobDescription(event.target.value)}/>

        <label name="resume">Resumé</label>
        <textarea name="resume" placeholder="Enter your Resumé..." disabled={!inputAllowed} onChange={(event) => setResume(event.target.value)} />

        <label name="instructions">Other Instructions (Optional)</label>
        <textarea name="instructions" placeholder="Enter other details..." disabled={!inputAllowed} onChange={(event) => setInstructions(event.target.value)} />

        <button onClick={onGenerate} disabled={!inputAllowed}>Generate</button>
    </div>
    <div className='model-outputs'>
        <textarea readOnly className="output-text-box" value={generatedCoverLetter}/>
    </div>
    </>);
}

export default CoverLetterGeneratorPage;