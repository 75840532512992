import './LoginPage.css'
import React, { useRef, useState } from "react";
import { Component } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Turnstile } from '@marsidev/react-turnstile'

const SigninPage = (props) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [token, setToken] = useState("");
    const turnstileRef = useRef(null);
    const navigate = useNavigate();

    const onEmailChange = (event) => {
        setEmail(event.target.value);
    }

    const onPasswordChange = (event) => {
        setPassword(event.target.value);
    }

    const loginUser = async() => {
        let loginUrl = `${process.env.REACT_APP_BACKEND_URL}/login`
        try {
            const response = await fetch(loginUrl, {
                method: "post",
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({ email: email, password: password, token: token}),
                credentials: 'include'
            });
            const data = await response.json();
            return data;
        } catch (err) {
            console.error(err);
        }
    }

    const onSubmitSignin = () => {
        console.log("token", token);
        if (!token){
            console.log("Token Not Set!");
            return;
        }
        (async () => {
            let data = await loginUser();

            if(data.id){
                console.log("response:", data)
                console.log(`Login Success!`);

                props.loadUser(true, data.id, data.username, data.email);
                navigate("/");
            }else{
                console.log("Login Failed!");
                turnstileRef.current?.reset();//reset turnstile
            }
        })().catch(err => console.log);
    }

    const onTurnstileError = (err) => {
        console.log("Turnstile Error", err);
    }

    const onTurnstileExpire = (err) => {
        console.log("Turnstile Expire", err);
    }

    return (<>
    <h1>Login (WIP)</h1>
    <div className="login-form">
        <div className='login-input'>
            <label htmlFor="email-address">Email</label> 
            <input onChange={onEmailChange} type="email" name="email-address"  id="email-address" />
        </div>
        <div className="login-input">
            <label htmlFor="password">Password</label> 
            <input onChange={onPasswordChange} type="password" name="password"  id="password" />
        </div>
        <div className="login-input">
            <Turnstile ref={turnstileRef} siteKey={process.env.REACT_APP_TURNSTILE_SITE_KEY} options={{theme: 'light'}} onSuccess={setToken} onError={onTurnstileError} onExpire={onTurnstileExpire} />
        </div>
        <div className='login-submit'>
            <button onClick={onSubmitSignin} className='button1' type="submit" disabled={!token}>Sign In</button>
        </div>
        <div className='login-links'>
            <Link to="/register"><p>Create Account!</p></Link>
            <Link to="/register"><p>Forgot Password?</p></Link>
        </div>
    </div>
    </>);
}

export default SigninPage;