import './HomePage.css'
import React from "react";
import { Component } from 'react';
import logo from '../../../images/logos/JamBurLogoWhite.png'

class HomePage extends Component {
    render () {
        return (
            <div className='homepage'>
                <header>
                    <h1>Jambur Online</h1>
                    <p>AI Powered Tools</p>
                    <img src={logo} className="App-logo" alt="logo" />
                </header>
            </div>
        );
    }
}

export default HomePage;