import './AiToolsPage.css'
import React from "react";
import { Component } from 'react';
import { Link } from 'react-router-dom';
import PageBanner from "../../page-banner/PageBanner";

import corruptedBeatsLogo from '../../../images/projects/corrupted_beats_bg.png';
import chatbotAssistantIcon from '../../../images/ai-tools/chatbot_assistant_icon.png'
import coverletterGeneratorIcon from '../../../images/ai-tools/coverletter_generator_icon.png'

class AiToolsPage extends Component {
    render () {
        return (<>
        <h1>AI Tools</h1>
        <hr/>
        <div className='projects-list'>
            <PageBanner title="TTS" image={chatbotAssistantIcon}
            description={"Custom Trained Text to Speech Model."}
            page = "/ai-tools/tts" />
            <PageBanner title="Cover Letter Generator" image={coverletterGeneratorIcon}
            description={"AI powered Cover Letter generator."}
            page = "/ai-tools/coverletter" />
            <PageBanner title="Chatbot" image={chatbotAssistantIcon}
            description={"Custom made generative NLP model by Jambur."}
            page = "/ai-tools/chatbot" />
        </div>
        </>);
    }
}

export default AiToolsPage;