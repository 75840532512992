import React, { useState, useEffect } from "react";

import Modal from '../../modal/Modal';
import Widget from "./widgets/Widget";

const TTSAgentEditModal = (props) => {
    const {isActive, widgetOptions, onCancel, onSubmit} = props;
    const [activationHint, setActivationHint] = useState(<p>{"Speak all chat messages in stream chat."}</p>);
    const [widgetId, setWidgetId] = useState();
    const [name, setName] = useState("Stream TTS Widget");
    const [defaultVoice, setDefaultVoice] = useState("kumitecho");
    const [activation, setActivation] = useState("chat");
    const [maxLength, setMaxLength] = useState(100);
    const [queueLength, setQueueLength] = useState(5);

    useEffect(() => {
        if(widgetOptions){
            setWidgetId(widgetOptions.widget_id);
            setName(widgetOptions.name);
            setDefaultVoice(widgetOptions.options.default_voice);
            setActivation(widgetOptions.options.activation);
            setMaxLength(widgetOptions.options.max_length);
            setQueueLength(widgetOptions.options.queue_length);
        }
    }, [widgetOptions]);

    useEffect(() => {
        switch(activation){
            case "chat":
                setActivationHint(<p>{"Speak all chat messages in stream chat."}</p>);
                break;

            case "command":
                setActivationHint(<p>{"Speak from user commands in stream chat \"!tts <voice> <text>\"."}</p>);
                break;

            case "elected":
                setActivationHint(<p>{"Speak chat messages from the elected user in stream chat. (This is a WIP and will NOT work!)"}</p>);
                break;

            default:
                setActivationHint(null);
                break;
        }
    }, [activation]);


    const onModalSubmit = (event) => {
        let options = {
            default_voice: defaultVoice,
            activation: activation,
            max_length: maxLength,
            queue_length: queueLength,
        };
        onSubmit(widgetId, name, options);
    }

    return (
        <Modal title={`Edit ${name}`} isActive={isActive} onCancel={onCancel} onSubmit={onModalSubmit}>
            <div className='modal-input'>
                <label htmlFor='name'>Widget Name</label>
                <input placeholder='Enter Name...' name='name' id='name' value={name} onChange={(event) => setName(event.target.value)}/>
            </div>

            <div className='modal-input'>
                <label htmlFor='default-voice'>Default Voice</label>
                <select name="default-voice" id="default-voice" value={defaultVoice} onChange={(event) => setDefaultVoice(event.target.value)}>
                    <option value='kumitecho'>Kumitecho</option>
                    <option value='jambur'>Jambur</option>
                </select>
            </div>

            <div className='modal-input'>
                <label htmlFor='tts-activation'>TTS Activation</label>
                <select name="tts-activation" id="tts-activation" value={activation} onChange={(event) => setActivation(event.target.value)}>
                    <option name="tts-activation" value='chat'>Chat Messages</option>
                    <option name="tts-activation" value='command'>Commands</option>
                    <option name="tts-activation" value='elected'>Elected</option>
                </select>
                {activationHint}
            </div>

            <div className='modal-input'>
                <label htmlFor='max-length'>Max Text Length</label>
                <input placeholder='Enter Max Length...' name='max-length' id='max-length' type='number' min='0' value={maxLength} onChange={(event) => setMaxLength(event.target.value)}/>
            </div>

            <div className='modal-input'>
                <label htmlFor='queue-size'>Max Queue Length</label>
                <input placeholder='Enter Queue Length...' name='queue-size' id='queue-size' type='number' min='0' value={queueLength} onChange={(event) => setQueueLength(event.target.value)}/>
            </div>
        </Modal>
    );
}

const submitEditWidget = async(wid, name, options) => {
    let requestUrl = `${process.env.REACT_APP_BACKEND_URL}/v1/widgets/tts-agent`;
    try {
        const response = await fetch(requestUrl, {
            method: "PUT",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({ widget_id: wid, widget_name: name, options: options}),
            credentials: 'include'
        });
        const data = await response.json();
        return data;
    } catch (err) {
        console.error(err);
    }
}

export default TTSAgentEditModal;
export { submitEditWidget };