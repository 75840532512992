import './RegisterPage.css'
import React, { useState } from "react";
import { Component } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Turnstile } from '@marsidev/react-turnstile'

const RegiserPage = (props) => {
    const [email, setEmail] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [token, setToken] = useState("");
    const navigate = useNavigate();

    const onEmailChange = (event) => {
        setEmail(event.target.value);
    }

    const onUsernameChange = (event) => {
        setUsername(event.target.value);
    }

    const onPasswordChange = (event) => {
        setPassword(event.target.value);
    }

    const onConfirmPasswordChange = (event) => {
        setConfirmPassword(event.target.value);
    }

    const registerUser = async() => {
        let registerUrl = `${process.env.REACT_APP_BACKEND_URL}/register`
        try {
            const response = await fetch(registerUrl, {
                method: "post",
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({ email: email, username: username, password: password, token: token}),
                credentials: 'include'
            });
            const data = await response.json();
            return data
        } catch (err) {
            console.error(err);
        }
    }

    const onSubmitRegister = () => {
        console.log("token", token);
        if (!token){
            console.log("Token Not Set!");
            return;
        }
        (async () => {
            let data = await registerUser();

            if(data.id){
                console.log(`Register Success!`);

                props.loadUser(true, data.id, data.username, data.email);
                navigate("/");
            }else{
                console.log("Register Failed!");
            }
            console.log(data);
        })().catch(err => console.log);

        //props.loadUser(true, "123abc", username, email);
        //navigate("/");
    }

    const onTurnstileError = (err) => {
        console.log("Turnstile Error", err);
    }

    const onTurnstileExpire = (err) => {
        console.log("Turnstile Expire", err);
    }

    return (<>
    <h1>Register (WIP)</h1>
    <div className="form">
        <div className='register-input'>
            <label htmlFor="email-address">Email</label> 
            <input onChange={onEmailChange} type="email" name="email-address"  id="email-address" />
        </div>
        <div className='register-input'>
            <label htmlFor="username">Username</label> 
            <input onChange={onUsernameChange} type="text" name="username"  id="username" />
        </div>
        <div className="register-input">
            <label htmlFor="password">Password</label> 
            <input onChange={onPasswordChange} type="password" name="password"  id="password" />
        </div>
        <div className="register-input">
            <label htmlFor="confirm-password">Confirm Password</label> 
            <input onChange={onConfirmPasswordChange} type="password" name="confirm-password"  id="confirm-password" />
        </div>
        <div className="register-input">
            <Turnstile siteKey={process.env.REACT_APP_TURNSTILE_SITE_KEY} options={{theme: 'light'}} onSuccess={setToken} onError={onTurnstileError} onExpire={onTurnstileExpire} />
        </div>
        <div className='register-submit'>
            <button onClick={onSubmitRegister} className='button1' type="submit" disabled={!token}>Create Account</button>
        </div>
    </div>
    </>);
}

export default RegiserPage;