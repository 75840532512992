import './Widget.css'
import React from "react";

const Widget = (props) => {
    const { image, title, description, onClick} = props;

    return (
        <div className="widget-panel selectable" onClick={onClick}>
            <img className='widget-img-background' src={image} />
            <div className='widget-info'>
                <h1>{title}</h1>
                <p>{description}</p>
            </div>
        </div>
    );
}

export default Widget;