import './TwitchToolsPage.css'
import React, { useState, useEffect } from "react";
import { Component } from 'react';
import { Link } from 'react-router-dom';

import Modal from '../../modal/Modal';
import TTSAgentModal from './TTSAgentModal';
import TTSAgentEditModal, { submitEditWidget } from './TTSAgentEditModal';
import Widget from "./widgets/Widget";
import ActiveWidget from "./widgets/ActiveWidget";

import test_img from '../../../images/icons/EditIcon.png';

const TwitchToolsPage = (props) => {
    const [addWidgetModalActive, setAddWidgetModalActive] = useState(false);
    const [editWidgetModalActive, setEditWidgetModalActive] = useState(false);
    const [selectedWidget, setSelectedWidget] = useState();
    const [widgets, setWidgets] = useState();
    const [widgetsElements, setWidgetsElements] = useState();

    useEffect(() => {
        console.log("Component Did Mount stuff TwitchTools");
        //load widgets
        loadWidgets();

        return () => {
            console.log("Component Did UnMount stuff TwitchTools");
        }
    }, []);

    useEffect(() => {
        renderWidgets();
    }, [widgets]);

    const loadWidgets = () => {
        (async () => {
            let getWidgetsURL = `${process.env.REACT_APP_BACKEND_URL}/get-widgets`
            try {
                const response = await fetch(getWidgetsURL, {
                    method: "get",
                    credentials: 'include'
                });
                const data = await response.json();
                setWidgets(data);
            } catch (err) {
                console.error(err);
            }
        })();
        console.log("Loaded Widgets!");
    }

    const renderWidgets = () => {
        console.log("widgets:", widgets);
        if (widgets && Array.isArray(widgets)){
            let widgetElements = widgets.map((widget) => {
                return <ActiveWidget image={test_img} title={widget.name} widgetUrl={`${process.env.REACT_APP_BACKEND_URL}/v1/tts-agent/${widget.widget_id}`}
                onEdit={(event) => onEditWidgetClick(event, widget)} 
                onDelete={(event) => onDeleteWidgetClick(event, widget)} 
                />
            });
            console.log("setting Widgets");
            setWidgetsElements(widgetElements);
        }
    }

    //redirect = backend / api.jamburonline/twitch-auth
    const onLinkTwitchClick = () => {
        let link = `${process.env.REACT_APP_BACKEND_URL}/twitch-redirect`;
        //window.open(link);
        window.location.href = link;
    }

    const onEditWidgetClick = (event, widget) => {
        setSelectedWidget(widget);
        setEditWidgetModalActive(true);
    }

    const onDeleteWidgetClick = (event, widget) => {
        (async () => {
            const deleted = await deleteWidget(widget.widget_id);
            //remove deleted from client array
            const deletedId = deleted.map(widget => widget.widget_id);
            let filteredWidgets = widgets.filter(widget => !deletedId.includes(widget.widget_id));
            setWidgets(filteredWidgets);
        })().catch(err => console.log);
    }

    const onEditWidgetModalCancel = () =>{
        setEditWidgetModalActive(false);
    }

    const onEditWidgetModalSubmit = (wid, name, options) =>{
        (async () => {
            const edited = await submitEditWidget(wid, name, options);
            console.log("Edited:", edited);
            //update edited in client array
            let newWidgets = widgets.map(widget => {
                if(widget.widget_id === edited.widget_id){
                    return {name: edited.name, options: edited.options, type: edited.type, widget_id: edited.widget_id};
                }
                return widget;
            });
            setWidgets(newWidgets);
        })().catch(err => console.log);
        setEditWidgetModalActive(false);
    }

    const onWidgetClick = (event) => {
        setAddWidgetModalActive(true);
    }

    const onAddWidgetModalCancel = () =>{
        setAddWidgetModalActive(false);
    }

    const onAddWidgetModalSubmit = (name, options) =>{
        (async () => {
            let createTTSAgentWidgetUrl = `${process.env.REACT_APP_BACKEND_URL}/v1/create-widget/tts-agent`;
            try {
                const response = await fetch(createTTSAgentWidgetUrl, {
                    method: "POST",
                    headers: {"Content-Type": "application/json"},
                    body: JSON.stringify({ widget_name: name, options: options}),
                    credentials: 'include'
                });
                const data = await response.json();
                setWidgets([...widgets, data]);
            } catch (err) {
                console.error(err);
            }
        })().catch(err => console.log);

        setAddWidgetModalActive(false);
    }

    const deleteWidget = async(wid) => {
        let requestUrl = `${process.env.REACT_APP_BACKEND_URL}/v1/widgets/tts-agent`;
        try {
            const response = await fetch(requestUrl, {
                method: "DELETE",
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({ widget_id: wid}),
                credentials: 'include'
            });
            const data = await response.json();
            return data;
        } catch (err) {
            console.error(err);
        }
    }

    return (<>
        <h1>Twitch Tools (WIP)</h1>
        <hr/>
        <div>
            <button className="button3" onClick={onLinkTwitchClick}>Link Twitch Account</button>
        </div>

        <h1>Widgets</h1>
        <div className="widgets">
            <Widget image={test_img} title="Stream TTS" description="Add text to speech to your streams."
            onClick={onWidgetClick}/>
            <Widget image={test_img} title="Coming Soon..." description="Coming Soon..."/>
        </div>
        <h1>Active Widgets</h1>
        <p>WARNING: Keep all Widget URL's private as they contain sensitive information</p>
        <div className='added-widgets'>
            {widgetsElements}
            {/*<ActiveWidget image={test_img} title="Widget 1"/>*/}
        </div>

        <TTSAgentEditModal isActive={editWidgetModalActive} widgetOptions={selectedWidget} onCancel={onEditWidgetModalCancel} onSubmit={onEditWidgetModalSubmit} />
        <TTSAgentModal isActive={addWidgetModalActive} onCancel={onAddWidgetModalCancel} onSubmit={onAddWidgetModalSubmit}/>
    </>);
}

export default TwitchToolsPage;