import './Modal.css'
import React, { Children } from "react";

const Modal = ({title, isActive, onCancel, onSubmit, children}) => {
    if(!isActive){
        return null;
    }

    return(<div className='backdrop' onClick={onCancel}>
        <div className="modal" onClick={(e) => e.stopPropagation()}>
            <h1>{title}</h1>
            <div className='modal-body'>
                {children}
            </div>
            <div className='modal-footer'>
                <input className='button3' onClick={onCancel} type="submit" value={"Cancel"} />
                <input className='button1' onClick={onSubmit} type="submit" value={"Submit"} />
            </div>
        </div>
    </div>);
}

export default Modal;