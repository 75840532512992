import './AccountPage.css'
import React, { useState, useEffect } from "react";
import { Component } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import Modal from '../../modal/Modal';
import eyeIcon from '../../../images/icons/EyeIconBlack.png';
import editIcon from '../../../images/icons/EditIconBlack.png';

const AccountPage = (props) => {
    const [tokenModalActive, setTokenModalActive] = useState(false);
    const [tokens, setTokens] = useState();
    const [tokensTableElements, setTokensTableElements] = useState();
    const [modalNameInput, setModalNameInput] = useState("");
    const navigate = useNavigate();

    const loadTokens = () => {
        (async () => {
            let getTokensURL = `${process.env.REACT_APP_BACKEND_URL}/get-tokens`
            try {
                const response = await fetch(getTokensURL, {
                    method: "get",
                    credentials: 'include'
                });
                const data = await response.json();
                setTokens(data);
                console.log(data);
            } catch (err) {
                console.error(err);
            }
        })();
        console.log("Loaded Tokens!");
    }

    useEffect(() => {
        console.log("Component Did Mount stuff account");
        //load tokens
        loadTokens();

        return () => {
            console.log("Component Did UnMount stuff account");
        }
    }, []);

    useEffect(() => {
        renderTokens();
    }, [tokens]);

    const logoutUser = async() => {
        let logoutUrl = `${process.env.REACT_APP_BACKEND_URL}/logout`
        try {
            const response = await fetch(logoutUrl, {
                method: "post",
                headers: {"Content-Type": "application/json"},
                credentials: 'include'
            });
            const data = await response.json();
            return data;
        } catch (err) {
            console.error(err);
        }
    }

    const onLogout = () => {
        (async () => {
            let data = await logoutUser();
            console.log("===NEED TO FIX===");
            if(data){//data always returns true as server still responds with unsuccesful message insteaqd i should check a state
                props.loadUser(false, "", "", "");
                navigate("/");
            } else {
                console.log("Unable to Logout");
            }
        })();
    }

    const createToken = async() => {
        if(!modalNameInput){
            return;
        }
        let permissions = {
            chatbot: true,
            tts: true
        };
        let tokenUrl = `${process.env.REACT_APP_BACKEND_URL}/create-token`;
        try {
            const response = await fetch(tokenUrl, {
                method: "post",
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({ token_name: modalNameInput, permissions: permissions}),
                credentials: 'include'
            });
            loadTokens();
            const data = await response.json();
            return data
        } catch (err) {
            console.error(err);
        }
    }

    const onCreateNewToken = () => {
        setTokenModalActive(true);
        setModalNameInput("");
    }

    const onTokenModalCancel = () =>{
        setTokenModalActive(false);
        setModalNameInput("");
    }

    const onTokenModalSubmit = () =>{
        (async () => {
            let data = await createToken();
            console.log("data:", data);

            if(data.id){
                console.log(`Create Token Success!`);
            }else{
                console.log("Create Token Failed!");
            }
        })().catch(err => console.log);

        setTokenModalActive(false);
        setModalNameInput("");
    }

    const handleModalNameInputChange = (event) =>{
        setModalNameInput(event.target.value);
    }

    const renderTokens = () => {
        console.log("tokens:", tokens);
        if (tokens && Array.isArray(tokens)){
            let tableElements = tokens.map((token) => {
                return <tr>
                    <td>{token.name}</td>
                    <td>{
                        (token.visible) ? token.access_token : "hidden..."
                    }</td>
                    <td>{JSON.stringify(token.permissions)}</td>
                    <td>
                        <input type='image' src={eyeIcon} onClick={(event) => handleTokenRevealClick(token.id, event)} />
                        <input type='image' src={editIcon} />
                    </td>
                </tr>
            });
            console.log("setting tokens");
            setTokensTableElements(tableElements);
        }
    }

    const handleTokenRevealClick = (id, event) => {
        const newTokens = [...tokens];
        const index = newTokens.findIndex(token => token.id === id);
        newTokens[index].visible = !newTokens[index].visible;
        setTokens(newTokens);
    }

    return (<>
    <h1>Welcome {props.user.username}</h1>
    <div className="account-form">
        <input className='button2' onClick={onLogout} type="submit" value="Logout"/>
        <h1>API Keys</h1>
    </div>
    <div className='apikey-form'>
        <table className='apikey-table'>
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Access Token</th>
                    <th>Permissions</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {tokensTableElements}
            </tbody>
        </table>
        <input className='button3' onClick={onCreateNewToken} type="submit" value="Create new Token"/>
    </div>
    <Modal title={"Create New Token"} isActive={tokenModalActive} onCancel={onTokenModalCancel} onSubmit={onTokenModalSubmit} className="token-modal">
        <div className='modal-input'>
            <label htmlFor='name'>Name</label>
            <input placeholder='Enter Name...' name='name' id='name' value={modalNameInput} onChange={handleModalNameInputChange}></input>
        </div>
    </Modal>
    </>);
}

export default AccountPage;