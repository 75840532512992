import './Loading.css'
import React from "react";
import { Component } from 'react';

//import discordLogo from '../../images/discord-mark-black.png'
//import twitchLogo from '../../images/twitch_black.png'
import loadingIcon from '../../images/loading_icon.png'

const Loading = (props) => {

    return (
    <div className='loading_screen'>
        <h1>Loading...</h1>
        <img src={loadingIcon} alt="Loading Icon" className="loading-icon" />
    </div>
    );
}

export default Loading;