import React, { useState } from "react";

import Modal from '../../modal/Modal';

const TTSAgentModal = (props) => {
    const {isActive, onCancel, onSubmit} = props;
    const [activationHint, setActivationHint] = useState(<p>{"Speak all chat messages in stream chat."}</p>);
    const [name, setName] = useState("Stream TTS Widget");
    const [defaultVoice, setDefaultVoice] = useState("kumitecho");
    const [activation, setActivation] = useState("chat");
    const [maxLength, setMaxLength] = useState(100);
    const [queueLength, setQueueLength] = useState(5);

    const onActivationSelect = (event) => {
        setActivation(event.target.value);
        switch(event.target.value){
            case "chat":
                setActivationHint(<p>{"Speak all chat messages in stream chat."}</p>);
                break;

            case "command":
                setActivationHint(<p>{"Speak from user commands in stream chat \"!tts <voice> <text>\"."}</p>);
                break;

            case "elected":
                setActivationHint(<p>{"Speak chat messages from the elected user in stream chat. (This is a WIP and will NOT work!)"}</p>);
                break;

            default:
                setActivationHint(null);
                break;
        }
    }

    const onModalSubmit = (event) => {
        let options = {
            default_voice: defaultVoice,
            activation: activation,
            max_length: maxLength,
            queue_length: queueLength,
        };
        onSubmit(name, options);
    }

    return (
        <Modal title={"Add Stream TTS Widget"} isActive={isActive} onCancel={onCancel} onSubmit={onModalSubmit}>
            <div className='modal-input'>
                <label htmlFor='name'>Widget Name</label>
                <input placeholder='Enter Name...' name='name' id='name' value={name} onChange={(event) => setName(event.target.value)}/>
            </div>

            <div className='modal-input'>
                <label htmlFor='default-voice'>Default Voice</label>
                <select name="default-voice" id="default-voice" value={defaultVoice} onChange={(event) => setDefaultVoice(event.target.value)}>
                    <option value='kumitecho'>Kumitecho</option>
                    <option value='jambur'>Jambur</option>
                </select>
            </div>

            <div className='modal-input'>
                <label htmlFor='tts-activation'>TTS Activation</label>
                <select name="tts-activation" id="tts-activation" value={activation} onChange={(event) => onActivationSelect(event)}>
                    <option name="tts-activation" value='chat'>Chat Messages</option>
                    <option name="tts-activation" value='command'>Commands</option>
                    <option name="tts-activation" value='elected'>Elected</option>
                </select>
                {activationHint}
            </div>

            <div className='modal-input'>
                <label htmlFor='max-length'>Max Text Length</label>
                <input placeholder='Enter Max Length...' name='max-length' id='max-length' type='number' min='0' value={maxLength} onChange={(event) => setMaxLength(event.target.value)}/>
            </div>

            <div className='modal-input'>
                <label htmlFor='queue-size'>Max Queue Length</label>
                <input placeholder='Enter Queue Length...' name='queue-size' id='queue-size' type='number' min='0' value={queueLength} onChange={(event) => setQueueLength(event.target.value)}/>
            </div>
        </Modal>
    );
}

export default TTSAgentModal;