import './ChatbotBox.css'
import React, { useEffect, useRef } from 'react';

const ChatbotBox = (props) => {
    const { messages } = props;
    const chatboxRef = useRef();
    const bottomRef = useRef();

    let mappedMessages = messages.map(msg => {
        if (msg.name === "user"){
            return (<div className='left message'>{msg.message}</div>)
        }else{
            return (<div className='right message'>{msg.message}</div>)
        }
    });

    useEffect(() => {
        //scroll when a message is added
        if(messages.length){
            bottomRef.current?.scrollIntoView({behavior: "smooth", block: "end"});
        }
    }, [messages.length]);//Effect runs when messages changes

    return (
        <div className='chatbox' ref={chatboxRef}>
            {mappedMessages}
            <div ref={bottomRef} />
        </div>
    );
}

export default ChatbotBox;