import './TTSPage.css'
import React, { useState } from "react";

const TTSPage = (props) => {
    const [voice, setVoice] = useState("kumitecho");
    const [text, setText] = useState("");
    const [audioSrc, setAudioSrc] = useState("");
    const voice_ids = ["kumitecho", "jambur"]

    const getTTSResponse = async() => {
        try {
            let ttsUrl = `${process.env.REACT_APP_BACKEND_URL}/v1/tts`
            const response = await fetch(ttsUrl, {
                method: "post",
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({ voice_id: voice, text: text }),
                credentials: 'include'
            });
            const data = await response.json();
            return data['audio'];
        } catch (err) {
            console.error(err);
        }
    }

    const onSendMessage = async(event) => {
        setAudioSrc("");
        console.log("sending", text, voice);
        let audio = await getTTSResponse();
        setAudioSrc(`data:audio/wav;base64,${audio}`);
        console.log("response", audioSrc);
    }
    
    return (<>
    <h1>Text To Speech</h1>
    <hr/>
    <div className='model-inputs'>
        <select name="voice" onChange={(event) => setVoice(event.target.value)}>
            <option value={'kumitecho'}>Kumitecho</option>
            <option value={'jambur'}>Jambur</option>
        </select>
        <input name="text" placeholder="Enter Text..." onChange={(event) => setText(event.target.value)} />
        <button onClick={onSendMessage}>Send</button>
    </div>
    <div className='model-outputs'>
        {audioSrc && (
            <audio controls src={audioSrc}>
                Your browser does not support the audio element.
            </audio>
        )}
    </div>
    </>);
}

export default TTSPage;