import './ChatbotPage.css'
import React from "react";
import { Component } from 'react';

import ChatbotBox from './ChatbotBox';

class ChatbotPage extends Component {
    constructor(){
        super();
        this.state = {
            inputValue: '',
            inputAllowed: true,
            chatLog: []
        };
    }

    getChatbotResponse = async(message) => {
        let { inputValue, inputAllowed, chatLog } = this.state;

        try {
            this.setState({inputAllowed: false});
            let botUrl = `${process.env.REACT_APP_BACKEND_URL}/v1/chatbot`
            const response = await fetch(botUrl, {
                method: "post",
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({ message: message }),
                credentials: 'include'
            });
            const data = await response.json();
            console.log(data['response']);
            this.setState({inputAllowed: true});
            return data['response'];
        } catch (err) {
            this.setState({inputAllowed: true});
            console.error(err);
        }
    }

    onInputChange = (event) => {
        this.setState({ inputValue: event.target.value });
    }

    onSendMessage = async(event) => {
        let { inputValue, inputAllowed, chatLog } = this.state;
        let bot_input = inputValue;
        if (!inputValue.trim()) return;
        chatLog.push({name: "user", message: inputValue});
        this.setState({ chatLog: chatLog, inputValue: '' });

        let response = await this.getChatbotResponse(bot_input);
        chatLog.push({name: "bot", message: response});
        this.setState({ chatLog: chatLog, inputValue: '' });
    }

    render () {
        let { inputValue, inputAllowed, chatLog } = this.state;
        
        return (<>
        <h1>Chatbot (WIP)</h1>
        <hr/>
        <ChatbotBox messages={this.state.chatLog} />
        <div className='model-inputs'>
            <input name="input" placeholder="Enter Text..." value={inputValue} onChange={this.onInputChange} disabled={!inputAllowed} />
            <button onClick={this.onSendMessage} disabled={!inputAllowed}>Send</button>
        </div>
        </>);
    }
}

export default ChatbotPage;