import './ActiveWidget.css'
import React, { useState } from "react";

import eyeIcon from '../../../../images/icons/EyeIcon.png';

const Widget = (props) => {
    const { image, title, widgetUrl, onEdit, onDelete} = props;
    const [visible, setVisible] = useState(false);

    return (
        <div className="active-widget-panel">
            <h1>{title}</h1>
            <hr/>
            <img className='active-widget-img-background' src={image} />
            <div className='widget-info'>
                <div className='widget-url'>
                    <p>Widget URL:</p>
                    <input value={visible ? widgetUrl : "hidden..."} className='widget-url-code' readOnly/>
                    <input type='image' src={eyeIcon} className='widget-url-reveal-icon' onClick={(event) => setVisible(!visible)}/>
                </div>
                <div className='actions'>
                    <input className='button3' value={"Edit"} onClick={onEdit}/>
                    <input className='button2' value={"Delete"} onClick={onDelete}/>
                </div>
            </div>
        </div>
    );
}

export default Widget;